*{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;;
}

.productBorder{

    border: 5px solid purple;
    padding: 30px;
    margin: 50px 10px;
    text-align: center;
}

.showproduct{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.productBorder p{
  margin: 10px
}
