*{
    margin: 0;
    padding: 0;
}

.Checkout{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 10px;
    padding: 30px;
    text-align: center;
    background-color: pink;
    border: 3px solid rgb(196, 12, 180);
    font-size: 20px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;;

}

.Checkout p{
    font-size: 20px;
}
