.Btn{
    padding: 3px 8px;
}

.display{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text{
    font-size: 16px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;;
}