*{
    margin: 0;
    padding: 0;
}

.nav{
    display: flex;
    background-color: purple;
    justify-content: space-evenly;
    padding: 20px 0px 30px 0px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 3px 2px 14px 2px rgba(0,0,0,0.16); 
    box-shadow: 0px 10px 13px -7px #000000, 3px 2px 14px 2px rgba(0,0,0,0.16);
}

.HomeBtn{
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: 2px solid yellow;
    border-radius: 20px 20px;
    padding: 16px;
    background-color: yellow;
    color: black;
    text-decoration: none;
}
